
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Multiselect from 'vue-multiselect';
  import { FETCH_STATES } from '@/store/states';
  import { State } from '@/types/entities';

  @Component({
    components: { Multiselect },
    computed: {
      states() {
        return this.$store.state.states.statesList;
      }
    }
  })
  export default class StatesMultiSelector extends Vue {
    @Prop() value: number[];

    created() {
      this.search();
    }

    search() {
      this.$store.dispatch(FETCH_STATES);
    }

    valueChanged(eventName: string, selectedStates: State[]) {
      this.$emit(eventName, selectedStates);
    }
  }
