
  import ImagesMixin from '@/mixins/images.mixin';
  import ImageUpload from '@/components/ImageUpload.vue';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterTextarea from '@/components/HipsterTextarea.vue';
  import StatesMultiSelector from '@/components/StatesMultiSelector.vue';
  import slug from 'slug';
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import { Bookmaker, NotStrictBookmaker } from '@/types/entities';
  import { validationMixin } from 'vuelidate';
  import { maxValue, minValue, required } from 'vuelidate/lib/validators';
  import { mapMutations } from 'vuex';
  import { SET_BOOKMAKER_ITEM_VALUE } from '@/store/bookmakers';
  import ContentEditor from '@/components/ContentEditor.vue';

  @Component({
    components: {
      HipsterCheckbox,
      ImageUpload,
      HipsterInput,
      HipsterSelector,
      HipsterTextarea,
      InspiniaIbox,
      StatesMultiSelector,
      ContentEditor
    },
    mixins: [ validationMixin ],
    validations() {
      return {
        bookmaker: {
          name: { required },
          image_id: { required },
          review: { required },
          signup_bonus: { required },
          key_points: { required },
          rating: { required, minValue: minValue(1), maxValue: maxValue(5) },
          advantages: { required },
          bet_with: { required },
          deposit: { required },
          bonus: { required },
          referral_link: { required },
          slug: { required }
        }
      };
    },
    methods: {
      ...mapMutations({
        setBookmakerValue: SET_BOOKMAKER_ITEM_VALUE
      })
    }
  })
  export default class BookmakerEdit extends Mixins(ImagesMixin) {
    @Prop() bookmaker: Bookmaker;
    @Prop({ default: false }) prefillSlug: boolean;

    public setName(name: string) {
      const data: NotStrictBookmaker = { name };

      if (this.prefillSlug) {
        data.slug = slug(name);
      }

      // TODO: Need to implement it through mutations decorators
      (this as any).setBookmakerValue(data);
    }
  }
